export const firebaseConfig = {
  apiKey: 'AIzaSyDZO4uVdpSFhBFiNtISHN0tS48Eii8NsPQ',
  authDomain: 'kampery-kamil-cms.firebaseapp.com',
  projectId: 'kampery-kamil-cms',
  storageBucket: 'kampery-kamil-cms.appspot.com',
  messagingSenderId: '718599549943',
  appId: '1:718599549943:web:87dc4f1f69f2fe729134f2',
  measurementId: 'G-4XJ1CGV0QB',
};

export const adminEmails = ['wilq1986@gmail.com', 'kamperynawynajem@gmail.com'];
